try {
  eval('(function() { async _ => _; })();');
  if (typeof fetch !== 'function') throw new Error('No fetch api');
} catch (e) {
  console.log('\n\nBAD\n\n');

  if (location.pathname !== '/legacy-browser.html') {
    location.replace('/legacy-browser.html');
    //return;
  }

  window.setTimeout(function () {
    // fallback render for development
    var body = document.getElementsByTagName('body')[0];
    body.className = 'message';
    body.innerHTML =
      '\
        <h1>Unsupported Browser</h1> \
        <p>For security reasons, only current/green browsers are supported.</p> \
        <p>Current versions of Chrome, Firefox, Safari and Edge should work</p> \
        <p>For more information see <a href="https://browsehappy.com/">browsehappy.com</a></p> \
    ';
  }, 500);

  // remove all scripts
  var r = document.getElementsByTagName('script');
  for (var i = r.length - 1; i >= 0; i--) {
    r[i].parentNode.removeChild(r[i]);
  }
}
